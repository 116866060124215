export const PROD_URL = process.env.GATSBY_API_URL;
export const REQUEST_DEMO_API = `${PROD_URL}/api/v0/requestDemo`;
export const ROUTE_FLAG = {
  paymentPolicyPage: 'paymentPolicyPage',
  homePage: 'homePage',
};

export const FEATURES_DATA = {
  'dispensary':{
    title:'Easiest way to set up a dispensary in your clinic/hospital',
    description:[
      'All softwares included: Billing, Automated Procurement and Doctor\'s App',
      'Clinic space not a constraint- start both small and large dispensaries within 2 days',
      'All regulatory compliances pre-built in the system'
    ],
    image:'clinic_dispensary.png',
    orientation:'right'
  },
  'procurement':{
    title:'AI Based Procurement',
    description: [
      'AI predicts the medicines you need, and automatically orders the right quantity at the best margins',
      'Access best margins across network of 500+ distributors',
      'We handle the procurement for you but give you total control if you want to calncel/edit/add new orders!'
    ],
    image:'procurement.png',
    orientation:'left'
  },
  'support':{
    title:'Personal Support!',
    description:[
      'Dedicated Relationship Manager to help run your dispensary better',
      'Train existing staff and follow best-in-class dispenary management practices'
    ],
    image:'support_dispensary.png',
    orientation:'right'
  },
  'benefits':{
    title:'Added benefits',
    description:[
      'Digitise handwritten prescriptions to maintain electronic medical records',
      'Automatically send prescriptions to patients\' mobile'
    ],
    image:'benefit_dispensary.png',
    orientation:'left'
  }
}

export const SUCCESS_FLAG = 'success';
export const ERROR_FLAG = 'error';
export const DEMO_TYPE = 'dispensary';
export const WEBSITE = 'DISPENSARY';
